import React from 'react'
import {Text} from 'wix-ui-tpa/Text'
import {TextField} from 'wix-ui-tpa/TextField'
import s from './name.scss'

export const Name = () => (
  <>
    <Text>Name</Text>
    <TextField className={s.input} />
  </>
)
