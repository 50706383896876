import React from 'react'
import {Button} from 'wix-ui-tpa/Button'
import {Text, TYPOGRAPHY} from 'wix-ui-tpa/Text'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {unblockSoundIOS} from '@wix/live-video-components'
import {classes} from './join-with-sound.st.css'

export const JoinWithSound: React.FC<{onClose: any; webinar: boolean}> = ({onClose, webinar}) => {
  const {t} = useTranslation()
  const onClick = () => {
    unblockSoundIOS()
    onClose()
  }

  const title = webinar ? t('allowWebinarSound_title') : t('allowMeetingSound_title')
  const text = webinar ? t('allowWebinarSound_text') : t('allowMeetingSound_text')

  return (
    <div className={classes.container}>
      <div className="content" style={{textAlign: 'center'}}>
        <Text className={classes.text} typography={TYPOGRAPHY.largeTitle}>
          {title}
        </Text>
        <div style={{marginTop: '24px', marginBottom: '36px'}}>
          <Text className={classes.text} typography={TYPOGRAPHY.listText} tagName="div">
            {text}
          </Text>
        </div>
        <Button upgrade onClick={onClick} fullWidth>
          {t('allowSound_buttonText')}
        </Button>
      </div>
    </div>
  )
}
