import React from 'react'
import {useEnvironment} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import {Dialog} from 'wix-ui-tpa/Dialog'
import s from './generic-modal.scss'
import {GenericModalProps} from '.'

export const GenericModal = ({title, text, onClose}: GenericModalProps) => {
  const {isMobile} = useEnvironment()

  return (
    <Dialog isOpen={true} onClose={onClose}>
      <div className={classNames(s.container, {[s.mobile]: isMobile})}>
        <div className={s.title}>{title}</div>
        {text && <div className={s.text}>{text}</div>}
      </div>
    </Dialog>
  )
}
