import {StyleParamType, wixFontParam, createStylesParams} from '@wix/yoshi-flow-editor/tpa-settings'

type IStylesParams = {
  waitingRoomCardBackgroundColor: StyleParamType.Color
  waitingRoomButtonBackgroundColor: StyleParamType.Color
  waitingRoomTitleTextFont: StyleParamType.Font
  waitingRoomTitleTextColor: StyleParamType.Color
  waitingRoomDateTextFont: StyleParamType.Font
  waitingRoomDateTextColor: StyleParamType.Color
  waitingRoomInputTextFont: StyleParamType.Font
  waitingRoomInputTextColor: StyleParamType.Color
  waitingRoomButtonTextFont: StyleParamType.Font
  waitingRoomButtonTextColor: StyleParamType.Color
  waitingRoomStatusTextFont: StyleParamType.Font
  waitingRoomStatusTextColor: StyleParamType.Color
  videoTitleTextFont: StyleParamType.Font
  videoTitleTextColor: StyleParamType.Color
  backgroundColor: StyleParamType.Color
  waitingScreenBackgroundColor: StyleParamType.Color
  waitingRoomButtonBorderRadius: StyleParamType.Number
  waitingRoomButtonBorderColor: StyleParamType.Color
  waitingRoomButtonBorderWidth: StyleParamType.Number
  endScreenCardBackgroundColor: StyleParamType.Color
  endScreenTitleTextColor: StyleParamType.Color
  endScreenTitleTextFont: StyleParamType.Font
  endScreenButtonColor: StyleParamType.Color
  endScreenButtonFont: StyleParamType.Font
  endScreenButtonBackgroundColor: StyleParamType.Color
  endScreenButtonBorderRadius: StyleParamType.Number
}

const getBlack = () => ({value: 'rgba(0, 0, 0, 1)', name: 'color_2', opacity: 1})
const getWhite = () => ({value: 'rgba(255, 255, 255, 1)', name: 'color_1', opacity: 1})

export default createStylesParams<IStylesParams>({
  waitingRoomCardBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: getWhite,
  },
  waitingRoomButtonBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: getBlack,
  },
  waitingRoomTitleTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 28,
      family: 'Helvetica',
      style: {
        bold: true,
        italic: false,
        underline: false,
      },
    }),
  },
  waitingRoomTitleTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: getBlack,
  },
  waitingRoomDateTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
      family: 'Helvetica',
    }),
  },
  waitingRoomDateTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: getBlack,
  },
  waitingRoomInputTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 14,
      family: 'Helvetica',
    }),
  },
  waitingRoomInputTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: getBlack,
  },
  waitingRoomButtonTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
      family: 'Helvetica',
    }),
  },
  waitingRoomButtonTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: getWhite,
  },
  waitingRoomStatusTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
      family: 'Helvetica',
    }),
  },
  waitingRoomStatusTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: getBlack,
  },
  videoTitleTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 20,
      family: 'Helvetica',
      style: {
        bold: true,
        italic: false,
        underline: false,
      },
    }),
  },
  videoTitleTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: getWhite,
  },
  backgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: getBlack,
  },
  waitingScreenBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: getBlack,
  },
  waitingRoomButtonBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  waitingRoomButtonBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: getBlack,
  },
  waitingRoomButtonBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  endScreenCardBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: getWhite,
  },
  endScreenTitleTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: getBlack,
  },
  endScreenTitleTextFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 28,
      family: 'Helvetica',
      style: {
        bold: true,
        italic: false,
        underline: false,
      },
    }),
  },
  endScreenButtonColor: {
    type: StyleParamType.Color,
    getDefaultValue: getWhite,
  },
  endScreenButtonFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
      family: 'Helvetica',
      style: {
        bold: false,
        italic: false,
        underline: false,
      },
    }),
  },
  endScreenButtonBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: getBlack,
  },
  endScreenButtonBorderRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
})
