import React from 'react'
import {SIZE} from 'wix-ui-tpa/Button'
import {useTranslation, useEnvironment} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import {Button} from '../waiting-form/button-with-status/button'
import {DH} from '../../../../data-hooks'
import s from './error-popup.scss'
import {ErrorPopupProps} from './interfaces'

export const ErrorPopup: React.FC<ErrorPopupProps> = ({title, lineOne, lineTwo, learnMoreUrl, hideRefresh = false}) => {
  const {t} = useTranslation()
  const {isMobile} = useEnvironment()

  const reload = () => {
    window.location.reload()
  }

  return (
    <div className={classNames(s.container, {[s.mobile]: isMobile})}>
      <div className={s.content}>
        <div className={s.title}>{title}</div>
        <div className={s.text}>
          {`${lineOne} `}
          {learnMoreUrl && (
            <a href={learnMoreUrl} target="_blank" rel="noreferrer" className={s.link}>
              {t('errorPopupLearnHow')}
            </a>
          )}
        </div>
        {lineTwo && <div className={s.text}>{lineTwo}</div>}
        {!hideRefresh && (
          <Button className={s.button} size={SIZE.large} data-hook={DH.REFRESH_BUTTON} onClick={reload}>
            {t('errorPopupRefresh')}
          </Button>
        )}
      </div>
    </div>
  )
}
