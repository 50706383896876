import classNames from 'classnames'
import React, {useEffect} from 'react'
import {TextButton} from 'wix-ui-tpa/TextButton'
import {useEnvironment, useTranslation} from '@wix/yoshi-flow-editor'
import {LocalVideoPreview} from '@wix/live-video-components'
import {DH} from '../../../../data-hooks'
import {useMediaDevicesContext} from '../../media-devices-provider/media-devices-provider'
import {useWidgetState} from '../../widget-state-provider'
import {useModals} from '../../../hooks/modals'
import {ModalType} from '../../../interfaces'
import s from './preview.scss'
import {Controls} from './controls'

export const Preview = () => {
  const {showModal} = useModals()
  const {isMobile} = useEnvironment()
  const {video} = useWidgetState(state => state.controls)
  const {t} = useTranslation()
  const {
    permissionsDenied,
    hasPermissions,
    requestPermissions,
    selectedCamera,
    unsupportedBrowser,
  } = useMediaDevicesContext()
  const error = !hasPermissions || unsupportedBrowser

  useEffect(() => {
    if (unsupportedBrowser) {
      showModal(ModalType.UNSUPPORTED_BROWSER)
    }
  }, [])

  const getErrorOutput = () => {
    if (unsupportedBrowser) {
      return <UnsupportedBrowser t={t} />
    }

    if (permissionsDenied) {
      return <PermissionsDenied t={t} />
    }

    return <PermissionsRequired t={t} requestPermissions={requestPermissions} />
  }

  const renderError = () => {
    return <div className={s.errorMessage}>{getErrorOutput()}</div>
  }

  return (
    <>
      <div className={classNames(s.container, {[s.mobile]: isMobile, [s.error]: error})}>
        {error ? (
          renderError()
        ) : (
          <>
            <LocalVideoPreview video={video} dataHook={DH.VIDEO_PREVIEW} selectedCamera={selectedCamera} />
            <Controls />
          </>
        )}
      </div>
    </>
  )
}

const PermissionsDenied = ({t}) => (
  <>
    <div className={s.title}>{t('permissionDeniedLineOne')}</div>
    <span>{`${t('permissionDeniedLineTwo')} `}</span>
    <a
      href="https://support.wix.com/en/article/providing-camera-and-microphone-access-for-wix-live-events"
      target="_blank"
      rel="noreferrer"
      className={s.link}
    >
      {t('permissionDeniedLearnHow')}
    </a>
  </>
)

const PermissionsRequired = ({t, requestPermissions}) => (
  <>
    <div>{t('accessMessageLineOne')}</div>
    <div>{t('accessMessageLineTwo')}</div>
    <TextButton data-hook={DH.REQUEST_PERMISSIONS} className={s.accessLink} onClick={requestPermissions}>
      {t('accessButton')}
    </TextButton>
  </>
)

const UnsupportedBrowser = ({t}) => (
  <div className={s.unsupportedBrowser}>
    <div className={s.title}>{t('modal.unsupportedBrowser.title')}</div>
    <div className={s.text}>{t('modal.unsupportedBrowser.text')}</div>
  </div>
)
