import React from 'react'
import classNames from 'classnames'
import {Tooltip} from 'wix-ui-tpa/Tooltip'
import s from './control-button.scss'
import {ControlButtonProps} from '.'

export const ControlButton: React.FC<ControlButtonProps> = ({
  Icon,
  active,
  className,
  text,
  textClassName,
  square,
  children,
  small,
  red,
  pimple,
  tooltipText,
  tooltipDisabled = false,
  dataHook,
  onClick = () => null,
}) => (
  <Tooltip
    data-hook={dataHook}
    className={s.container}
    disabled={!tooltipText || tooltipDisabled}
    moveBy={{x: 0, y: 8}}
    hideDelay={0}
    showDelay={0}
    content={<TooltipTextContainer>{tooltipText}</TooltipTextContainer>}
  >
    <div
      className={classNames(s.content, className, {
        [s.active]: active,
        [s.square]: square,
        [s.itemContainer]: children,
        [s.small]: small,
        [s.red]: red,
        [s.withText]: Boolean(text),
        [s.pimple]: pimple,
      })}
      onClick={onClick}
    >
      {Icon ? <Icon size={small ? 22 : 28} /> : null}
      {text ? <span className={textClassName}>{text}</span> : null}
      {children}
    </div>
  </Tooltip>
)

const TooltipTextContainer: React.FC = ({children}) => <div className={s.tooltipText}>{children}</div>
