import {createSettingsParams, SettingsParamType} from '@wix/yoshi-flow-editor/tpa-settings'

export interface WixImage {
  relativeUri: string
  width: number
  height: number
}

export type ISettingsParams = {
  backgroundImage: SettingsParamType.Object
  backgroundImageOpacity: SettingsParamType.Number
  waitingScreenDifferentBackground: SettingsParamType.Boolean
  waitingScreenBackgroundImage: SettingsParamType.Object
  waitingScreenBackgroundImageOpacity: SettingsParamType.Number
}

export default createSettingsParams<ISettingsParams>({
  backgroundImage: {
    type: 'Object' as any,
    getDefaultValue: () => ({}),
  },
  backgroundImageOpacity: {
    type: SettingsParamType.Number as any,
    getDefaultValue: () => 100,
  },
  waitingScreenDifferentBackground: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => false,
  },
  waitingScreenBackgroundImage: {
    type: 'Object' as any,
    getDefaultValue: () => ({}),
  },
  waitingScreenBackgroundImageOpacity: {
    type: SettingsParamType.Number as any,
    getDefaultValue: () => 100,
  },
})
